import { Button, ButtonProps } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { rgba } from "polished";
import React from "react";

import { prefixClassName } from "@smartrr/shared/components/primitives";
import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";

import { DotSpinner } from "./DotSpinner";

type HTMLAttributeAnchorTarget = "_self" | "_blank" | "_parent" | "_top";

interface IStyledButtonProps extends ButtonProps {
  /** `filled` -> Dark background */
  filled?: boolean;

  /** Displays loader within button*/
  isLoading?: boolean;

  /** Text shown after button is clicked  */
  confirmText?: string;

  /** Toggle for text after button is clicked */
  showConfirm?: boolean;

  /** Toggle for depressed button state */
  selected?: boolean;

  /** Toggle for disabled button state */
  disabled?: boolean;

  /** Target for link destination */
  target?: HTMLAttributeAnchorTarget;
}

const ButtonWrapper = styled(({ filled, isLoading, confirmText, showConfirm, ...props }: IStyledButtonProps) => (
  <Button {...props} />
))<IStyledButtonProps>`
  background-color: ${props => props.theme.modernSecondaryButtonColor};
  border-color: ${props => props.theme.modernSecondaryButtonBorderColor};
  border-radius: ${props => props.theme.modernButtonBorderRadius}px;
  border-style: solid;
  border-width: ${props => props.theme.modernButtonBorderWidth}px;
  color: ${props => props.theme.modernSecondaryButtonFontColor};
  font-family: ${props => props.theme.modernButtonCTAFontFamily};

  font-size: ${props => props.theme.modernButtonCTAFontSize}px;
  font-weight: ${props => props.theme.modernButtonCTAFontWeight};
  height: ${props => props.theme.modernButtonBorderHeight}px;
  letter-spacing: ${props => props.theme.modernButtonCTAFontLetterSpacing}px;
  line-height: ${props =>
    props.theme.modernButtonCTAFontLineHeight ? props.theme.modernButtonCTAFontLineHeight : "inherit"};

  margin-top: 15px;
  text-transform: none;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.modernSecondaryButtonColor};
    box-shadow: 0px 0px 5px ${props => props.theme.modernSecondaryButtonColor};
  }

  /*
  :active,:focus {
    background-color: ${props => props.theme.modernSecondaryButtonColor};
  }
  */

  ${props =>
    props.disabled &&
    `
      pointer-events: none;
      opacity: 50%;

      &:hover {
        cursor: not-allowed;
      }

      &.Mui-disabled {
        color: ${props.theme.modernPrimaryButtonFontColor};
        background-color: ${props.theme.modernPrimaryButtonColor};
        opacity: 0.4;
      }
  `}

  ${props =>
    props.filled &&
    !props.showConfirm &&
    `
      background-color: ${props.theme.modernPrimaryButtonColor};
      border-color: ${props.theme.modernPrimaryButtonBorderColor};
      color: ${props.theme.modernPrimaryButtonFontColor};

      :hover,:active,:focus {
        box-shadow: 0px 0px 5px ${props.theme.modernPrimaryButtonColor};
        background-color: ${props.theme.modernPrimaryButtonColor};
      }
  `}

  ${props =>
    props.selected &&
    `
      background-color: ${rgba(props?.theme?.modernPrimaryButtonColor ?? "#2e2e2e", 0.1)};

      :hover {
        background-color: ${rgba(props?.theme?.modernPrimaryButtonColor ?? "#2e2e2e", 0.1)};
        box-shadow: 0px 0px 5px ${props.theme.modernPrimaryButtonColor} !important;
      }
    `}
  ${props =>
    props.showConfirm &&
    `
      background-color: ${props.theme.modernPrimaryButtonColor};
      border-color: ${props.theme.modernPrimaryButtonBorderColor};
      color: ${props.theme.modernPrimaryButtonFontColor};
      pointer-events: none;
      opacity: 70%;
    `}
`;

export const AquariusButton = ({
  children,
  filled,
  isLoading,
  showConfirm,
  confirmText,
  selected,
  disabled,
  sx,
  target,
  ...props
}: IStyledButtonProps): JSX.Element => {
  const theme = useTheme<ICustomerPortalTheme>();

  return (
    <ButtonWrapper
      className={prefixClassName(filled ? "primary-button" : "secondary-button")}
      filled={filled}
      isLoading={isLoading}
      showConfirm={showConfirm}
      selected={selected}
      disabled={disabled || isLoading}
      target={target}
      sx={sx}
      {...props}
    >
      {isLoading ? (
        <DotSpinner
          size={25}
          color={filled ? theme.modernPrimaryButtonFontColor : theme.modernSecondaryButtonFontColor}
        />
      ) : showConfirm ? (
        confirmText
      ) : (
        children
      )}
    </ButtonWrapper>
  );
};
